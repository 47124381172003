import {
  getImages,
  uploadImages,
  removeImage,
  removeLoading,
  addLoading
} from "./DropzoneActions"


export default {
  getImages,
  uploadImages,
  removeImage,
  removeLoading,
  addLoading
}