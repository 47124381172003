export default {
  siteUrl: 'siteUrl',
  person: 'person',
  config: 'config',
  problem: 'problem',
  setDescription: 'setDescription',
  setGeoposition: 'setGeoposition',
  setLoadingGeoposition: 'setLoadingGeoposition',
  place: 'place',
  getThemes: 'getThemes',
  typicalProblemDetail: 'typicalProblemDetail',
}