import React from 'react';
import { Provider } from 'react-redux';
import Router from "./components/Router";


const App = props => {
  const {store, problemAttrs} = props;

  return(
    <Provider store={store}>
      <Router problemAttrs={problemAttrs}/>
    </Provider>
  )
};

export default App;