import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Panel, FormGroup, Radio} from 'react-bootstrap';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/ru';
import {LoopCircleLoading, RotateCircleLoading} from 'react-loadingg';
import ActionCreators from "../actions";
import debounce from '../../../utils/debounce';
import {ROUTES} from '../constants/actionTypes'
import Errors from '../components/Errors';
import loadingTypes from "../constants/loadingTypes";


const editorConfiguration = {
  toolbar: ['heading', '|', 'bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'fontColor',
    'fontBackgroundColor'],
  removePlugins: ['Heading'],
  language: 'ru'
};

const StepTwo = props => {
  const {siteUrl, loading, themes, typicalProblemId, problem} = props,
    {description, place} = problem;

  const [allThemes, setAllThemes] = useState(false),
    [searchTheme, setSearchTheme] = useState(false),
    [newData, setNewData] = useState(description || ""),
    [localThemes, setLocalThemes] = useState([]),
    [fullLocalThemes, setFullLocalThemes] = useState([]),
    [prevThemes, setPrevThemes] = useState([]);

  const loadPlace = loading.includes(loadingTypes.place);

  useEffect(() => {
    if (place?.entity_name && newData?.length > 15 && loadPlace)
      props.getThemes(description);
  }, [loadPlace]);

  useEffect(() => {
    if (themes.length) {
      setLocalThemes(themes.slice(0, 3));
      setFullLocalThemes(themes.slice(0, 5));
      setTimeout(() => setPrevThemes(localThemes), 1000);
    } else {
      setLocalThemes([]);
      setFullLocalThemes([]);
      setPrevThemes([]);
    }
    if (loading.includes(loadingTypes.setGeoposition))
      props.getProblemDetail();
    else if (loading.includes(loadingTypes.getThemes)) {
      setTimeout(() => setSearchTheme(false), 1000);
      props.removeLoading(loadingTypes.getThemes);
    }
  }, [loading]);

  useEffect(() => {
    allThemes && props.getThemes(newData);
  }, [allThemes]);

  // Используется, когда пользователь хочет выбрать тематику вручную
  const manualSelection = () => {
    const urlString = window.location.href,
      url = new URL(urlString);
    if (url.searchParams && url.searchParams.get("is_frame")) {
      props.close();
      window.open(`${siteUrl}/problemnye-temy`);
    } else
      window.location.href = `${siteUrl}/problemnye-temy`;
  };

  const next = () => props.setData({"typical_problem_id": typicalProblemId});

  const prev = () => {
    props.setTypicalProblemId(null);
    props.setRoute(ROUTES.STEP_ONE)
  };

  const checkCorrectnessData = () => !!(newData.length > 15 && typicalProblemId);

  const changeDescription = data => {
    props.setData({"description": data});
    if (data && data.length > 15) {
      props.getThemes(data);
      setSearchTheme(true);
      setAllThemes(false);
    } else
      props.setTypicalProblemId(null);
  };

  const onChangeEditor = debounce(changeDescription, 800);

  return (
    <Panel bsStyle="primary">

      <Panel.Heading>
        <Panel.Title className="d-flex justify-content-between" componentClass="h6">
          <span>Сообщить о проблеме</span>
          <i className="fa fa-times close-widget" aria-hidden="true" onClick={props.close}/>
        </Panel.Title>
      </Panel.Heading>

      {loading.includes(loadingTypes.problem) && place ?
        <Panel.Body
          className={themes.length > 3 && allThemes || window.innerWidth <= 480 ? "panel-body--full-height panel-body--overflow" : "panel-body--full-height"}>
          <Panel.Title componentClass="h6" className="mb-4">
            <strong>ШАГ 2 из 3.</strong> Опишите проблему
          </Panel.Title>
          <p className="panel-title__address"><i className="fa fa-map-marker pin pin-blue" aria-hidden="true"/><a
            className="address"
            onClick={prev}>{place?.entity_name || ""}</a>
          </p>
          <FormGroup>
            <CKEditor
              onInit={editor => {
                if (/Mobi/.test(navigator.userAgent)) {
                  editor.sourceElement.addEventListener("click", e => {
                    editor.model.change(writer => {
                      editor.editing.view.focus();
                      writer.setSelection(editor.model.document.getRoot(), 'end');
                    });
                  })
                }
                editor.ui.getEditableElement().parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.getEditableElement()
                );
              }}
              editor={DecoupledEditor}
              data={description}
              config={editorConfiguration}
              onChange={(e, editor) => {
                const data = editor.getData();
                setNewData(data);
                if (data.length <= 15) {
                  setLocalThemes([]);
                  setFullLocalThemes([]);
                  setPrevThemes([]);
                } else {
                  onChangeEditor(data);
                }
              }}
            />
          </FormGroup>
          {newData.length < 15 && localThemes.length === 0 &&
          <span><span className="text-danger">*</span>&nbsp;Опишите максимально точно вашу проблему</span>
          }
          <div
            className={Object.keys(localThemes).length && newData.length > 15 ? allThemes ? 'trans trans--active' : 'trans' : 'trans trans--closed'}>
            <FormGroup className="categories">
              <div className={"categories__search"}>
                {searchTheme ?
                  <h5>
                    Идёт поиск тематики...
                    <div className="circleLoading">
                      <RotateCircleLoading color="#337AB7" size="small"/>
                    </div>
                  </h5>
                  :
                  <>
                    {newData.length > 15 && localThemes.length > 0 &&
                    <h5>
                      Выберите категорию проблемы
                    </h5>}
                  </>
                }
              </div>
              <div
                className={localThemes.length && newData.length > 0 ? searchTheme ? "categories__results categories-results categories-results--search" : "categories__results categories-results" : "categories-results categories-results--closed"}>
                <div
                  className={searchTheme ? "categories-results__themes" : allThemes ? "categories-results__themes categories-results__themes--closed" : "categories-results__themes categories-results__themes--hidden"}>
                  {prevThemes.map((theme, themeKey) =>
                    <Radio name="short-typicalProblem" defaultChecked={!allThemes && typicalProblemId === theme.id}
                           key={themeKey} onClick={() => props.setTypicalProblemId(theme.id)}>
                      {theme.title}
                    </Radio>
                  )}
                </div>
                <div
                  className={searchTheme ? "categories-results__themes categories-results__themes--hidden" : allThemes ? "categories-results__themes categories-results__themes--closed" : "categories-results__themes"}>
                  {localThemes.map((theme, themeKey) =>
                    <Radio name="short-typicalProblem" defaultChecked={!allThemes && typicalProblemId === theme.id}
                           key={themeKey} onClick={() => props.setTypicalProblemId(theme.id)}>
                      {theme.title}
                    </Radio>
                  )}
                </div>
                <div
                  className={searchTheme ? "categories-results__themes categories-results__themes--closed" : allThemes ? "categories-results__themes" : "categories-results__themes categories-results__themes--closed"}>
                  {fullLocalThemes.map((theme, themeKey) =>
                    <Radio name="full-typicalProblem" defaultChecked={allThemes && typicalProblemId === theme.id}
                           key={themeKey} onClick={() => props.setTypicalProblemId(theme.id)}>
                      {theme.title}
                    </Radio>
                  )}
                </div>
              </div>
            </FormGroup>
            {newData.length > 15 &&
            <div>
              {allThemes || (!allThemes && themes.length <= 3) ?
                <p>или&nbsp;
                  <a onClick={manualSelection}>
                    укажите вручную в классификаторе проблем
                  </a>
                </p>
                :
                <>
                  {localThemes.length > 0 &&
                  <div
                    className={searchTheme ? "categories-results__other categories-results__other--hidden" : "categories-results__other"}>
                    <i className="fa fa-caret-right" aria-hidden="true"/>&nbsp;
                    <a onClick={() => setAllThemes(true)}>
                      Другие категории
                    </a>
                  </div>}
                </>
              }
            </div>
            }
          </div>
          {(Object.keys(themes).length === 0 && newData.length > 15) && !searchTheme &&
          <>
            <h5>Результатов не найдено</h5>
            <p>
              <a onClick={manualSelection}>
                Укажите вручную в классификаторе проблем
              </a>
            </p>
          </>
          }
          <Errors/>
          <div className="containerBtn">
            <Button bsSize="sm" className="prev" onClick={prev}>
              &#8592;&nbsp;Вернуться
            </Button>
            <Button bsStyle="info" bsSize="sm" className="next" disabled={!checkCorrectnessData()}
                    onClick={next}>
              Продолжить&nbsp;&#8594;
            </Button>
          </div>
        </Panel.Body>
        :
        <Panel.Body>
          <LoopCircleLoading color="#337AB7"/>
        </Panel.Body>
      }

    </Panel>
  )
};

const mapStateToProps = state => ({
  problem: state.problem,
  loading: state.loading,
  themes: state.themes,
  siteUrl: state.siteUrl,
  typicalProblemId: state.typicalProblemId
});
const mapDispatchToProps = dispatch => bindActionCreators(ActionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepTwo);
