import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ActionCreators from '../actions';
import {Button, Panel, Form, Alert} from 'react-bootstrap';
import {YMaps, Map, Placemark} from 'react-yandex-maps';
import {LoopCircleLoading} from 'react-loadingg';
import Errors from "./Errors";
import {ROUTES} from "../constants/actionTypes";
import loadingTypes from "../constants/loadingTypes";


let coordinates = [];

const mapModules = [
  'control.ZoomControl',
  'geolocation',
  'geocode'
];

const StepOne = props => {
  const {personId, problem, personDetail, loading, config, address, errors} = props,
    {place} = problem;

  const mapState = {
    center: coordinates,
    zoom: 16,
    controls: [
      'zoomControl',
    ],
    behaviors: [
      'drag',
      'dblClickZoom',
      'rightMouseButtonMagnifier',
      'multiTouch',
      'scrollZoom'
    ]
  };

  const [map, setMap] = useState(null),
    [instanceMap, setInstanceMap] = useState(null),
    [next, setNext] = useState(null),
    [hasPlace, setHasPlace] = useState(false),
    [openSearchAddresses, setOpenSearchAddresses] = useState(false),
    [inputValue, setInputValue] = useState(""),
    [allowNextOnEnter, setAllowNextOnEnter] = useState(false),
    [fio, setFio] = useState(''),
    [phone, setPhone] = useState(''),
    [email, setEmail] = useState('');

  useEffect(() => {
    fillDataApplicant()
  }, [])

  useEffect(() => {
    fillDataApplicant()
  }, [problem])

  useEffect(() => {
    if (loading.includes(loadingTypes.siteUrl) && !loading.includes(loadingTypes.person))
      props.getPersonDetail(personId);

    if (next && loading.includes(loadingTypes.setGeoposition)) {
      props.setRoute(ROUTES.STEP_TWO);
      props.getPlace();
      props.removeLoading(loadingTypes.setLoadingGeoposition);
      props.removeLoading(loadingTypes.problem);
      props.removeLoading(loadingTypes.place);
    }
  }, [loading]);

  useEffect(() => {
    coordinates = [config.LAT_DEFAULT, config.LNG_DEFAULT];
  }, [config]);

  useEffect(() => {
    if (place?.extra) {
      coordinates = place.extra.geoposition.split(',');
      coordinates[0] = +coordinates[0];
      coordinates[1] = +coordinates[1];
      setHasPlace(true);
    }
  }, [place]);

  useEffect(() => {
    if (address?.received && hasPlace)
      setInputValue(address.received.value);
  }, [address.received]);

  useEffect(() => {
    if (instanceMap && inputValue)
      instanceMap.setZoom(16, {duration: 1000});
  }, [inputValue]);

  useEffect(() => {
    if (address.received?.value === inputValue)
      setAllowNextOnEnter(true);
  });

  useEffect(() => {
    if (errors) {
      setNext(false);
      props.removeLoading(loadingTypes.setLoadingGeoposition);
    }
  },[errors])

  const fillDataApplicant = () => {
    if (Object.keys(problem).length) {
      setFio(problem.applicant_fio)
      setPhone(problem.applicant_phone)
      setEmail(problem.applicant_email)
    }
  }

  const nextStep = () => {
    const newGeoposition = `${coordinates[0]},${coordinates[1]}`;

    if (place && newGeoposition === place.extra.geoposition && !personDetail.isOperator)
      props.addLoading(loadingTypes.setGeoposition);
    else {
      let data = {
        geoposition: newGeoposition,
        address: inputValue,
        source_of_message: 10
      };

      if (personDetail.isOperator) {
        data = {
          ...data,
          applicant_fio: fio,
          source_of_message: phone ? 70 : 60
        }

        if (email)
          data.applicant_email = email;
        if (phone)
          data.applicant_phone = phone;
      }

      props.setData(data);
      props.addLoading(loadingTypes.setLoadingGeoposition);
    }

    setNext(true);
  };

  const setSearchCoordinates = item => {
    coordinates = item.coordinates;
    setInputValue(item.value);
    props.geocodeAddress(map, inputValue);
    setOpenSearchAddresses(false);
    props.getSearchAddress(map, coordinates);
    setHasPlace(true);
  };

  const loadMap = ymaps => {
    setMap(ymaps);
    props.getSearchAddress(ymaps, coordinates);
  };

  const dragPin = e => {
    coordinates = e.get('target').geometry.getCoordinates();
    props.getSearchAddress(map, coordinates);
    setHasPlace(true);
  };

  const checkNextStep = () => {
    if (personDetail.isOperator)
      return hasPlace && fio && (phone || email)
    return hasPlace
  }

  if (loading.includes(loadingTypes.person) && personDetail.entity_model !== "privateperson") {
    return (
      <Panel bsStyle="primary">

        <Panel.Heading>
          <Panel.Title className="d-flex justify-content-between" componentClass="h6">
            <span>Сообщить о проблеме</span>
            <i className="fa fa-times" aria-hidden="true" onClick={props.close}/>
          </Panel.Title>
        </Panel.Heading>

        <Panel.Body className="d-flex justify-content-center align-items-center">
          <Alert bsStyle="danger">
            <h4 className="text-center">Только частное лицо может оставлять сообщение</h4>
          </Alert>
        </Panel.Body>

      </Panel>
    )
  }

  return (
    <Panel bsStyle="primary">

      <Panel.Heading>
        <Panel.Title className="d-flex justify-content-between" componentClass="h6">
          <span>Сообщить о проблеме</span>
          <i className="fa fa-times close-widget" aria-hidden="true" onClick={props.close}/>
        </Panel.Title>
      </Panel.Heading>

      {!loading.includes(loadingTypes.setLoadingGeoposition) && loading.includes(loadingTypes.problem) ?
        <Panel.Body className="step-one">
          <Panel.Title componentClass="h6" className="mb-4">
            <strong>ШАГ 1 из 3.</strong> Укажите адрес проблемы
          </Panel.Title>
          <Form>
            {personDetail.isOperator ?
              <>
                <label>ФИО</label>
                <div className="data-operator">
                  <input name="name" value={fio} onChange={e => setFio(e.target.value)} placeholder="Укажите ФИО"/>
                </div>
                <label>Телефон</label>
                <div className="data-operator">
                  <input name="name" value={phone} onChange={e => setPhone(e.target.value)}
                         placeholder="Укажите телефон"/>
                </div>
                <label>Почта</label>
                <div className="data-operator">
                  <input name="name" value={email} onChange={e => setEmail(e.target.value)} placeholder="Укажите почту"/>
                </div>
              </>
              : null
            }
            <div className="search-address-container">
              <div className="search-address">
                <input name="address" type="text" placeholder="Начните ввод адреса ..." autoComplete="off"
                       onChange={e => {
                         if (allowNextOnEnter) setAllowNextOnEnter(false);
                         props.geocodeAddress(map, e.target.value);
                         setInputValue(e.target.value);
                         setOpenSearchAddresses(!!inputValue);
                       }}
                       onKeyDown={e => {
                         if (e.key === 'Enter') {
                           e.preventDefault();

                           if (allowNextOnEnter) {
                             nextStep();
                           } else {
                             if (openSearchAddresses)
                               setSearchCoordinates(address.searchAddresses[0]);
                           }
                         }
                       }}
                       value={inputValue}/>
                {openSearchAddresses &&
                <div className="search-address-container">
                  {address.searchAddresses && address.searchAddresses.map((item, key) => {
                    return (
                      <div className="search-address-container-item" key={key}
                           onClick={() => setSearchCoordinates(item)}>
                        <p>{item.value}</p>
                      </div>)
                  })}
                </div>
                }
              </div>
            </div>
          </Form>
          <YMaps
            query={{
              ns: "use-load-option",
              apikey: config.YANDEX_MAPS_RESTRICTED_API_KEY
            }}>
            <Map
              onLoad={ymaps => loadMap(ymaps)}
              modules={mapModules}
              width="100%"
              className={`map ${personDetail.isOperator && 'operator'}`}
              state={mapState}
              options={{suppressMapOpenBlock: true}}
              instanceRef={ymaps => setInstanceMap(ymaps)}>
              <Placemark
                onDragEnd={e => dragPin(e)}
                geometry={coordinates}
                options={{draggable: true}}/>
            </Map>
          </YMaps>
          <Errors/>
          <div className="containerBtn">
            <Button bsStyle="info" className="next" disabled={!checkNextStep()} bsSize="sm" onClick={nextStep}>
              Продолжить&nbsp;&#8594;
            </Button>
          </div>
        </Panel.Body>
        :
        <Panel.Body>
          <LoopCircleLoading color="#337AB7"/>
        </Panel.Body>
      }

    </Panel>
  )
};

const mapStateToProps = state => ({
  personDetail: state.personDetail,
  problem: state.problem,
  config: state.config,
  loading: state.loading,
  address: state.address,
  errors: state.errors
});
const mapDispatchToProps = dispatch => bindActionCreators(ActionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StepOne);
