// actions
export const actionTypes = {
  SET_ROUTE: 'SET_ROUTE',
  GET_PERSON_DETAIL: 'GET_PERSON_DETAIL',
  ERROR_GET_PERSON_DETAIL: 'ERROR_GET_PERSON_DETAIL',
  GET_PROBLEM_DETAIL: 'GET_PROBLEM_DETAIL',
  ERROR_GET_PROBLEM_DETAIL: 'ERROR_GET_PROBLEM_DETAIL',
  GET_CONFIG: 'GET_CONFIG',
  ERROR_GET_CONFIG: 'ERROR_GET_CONFIG',
  SET_DATA: 'SET_DATA',
  ERROR_SET_DATA: 'ERROR_SET_DATA',
  ADD_LOADING: 'ADD_LOADING',
  REMOVE_LOADING: 'REMOVE_LOADING',
  GET_PLACE: 'GET_PLACE',
  ERROR_GET_PLACE: 'ERROR_GET_PLACE',
  GET_THEMES: 'GET_THEMES',
  ERROR_GET_THEMES: 'ERROR_GET_THEMES',
  SUBMIT_PROBLEM: 'SUBMIT_PROBLEM',
  ERROR_SUBMIT_PROBLEM: 'ERROR_SUBMIT_PROBLEM',
  SET_SITE_URL: 'SET_SITE_URL',
  GET_ADDRESS: 'GET_ADDRESS',
  SEARCH_ADDRESSES: 'SEARCH_ADDRESSES',
  ERROR_SEARCH_ADDRESS: 'ERROR_SEARCH_ADDRESS',
  SET_TYPICAL_PROBLEM_ID: 'SET_TYPICAL_PROBLEM_ID',
  GET_TYPICAL_PROBLEM_DETAIL: 'GET_TYPICAL_PROBLEM_DETAIL',
  ERROR_GET_TYPICAL_PROBLEM_DETAIL: 'ERROR_GET_TYPICAL_PROBLEM_DETAIL',
  CHANGE_AMOUNT_IMAGE: 'CHANGE_AMOUNT_IMAGE'
}

// routes
export const ROUTES = {
  STEP_ONE: 1,
  STEP_TWO: 2,
  STEP_THREE: 3,
  FINISH_SCREEN: 4
};
