import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Panel } from 'react-bootstrap';
import ActionCreators from "../actions";


const FinishScreen = props => {

  const { siteUrl, personDetail, config, typicalProblem} = props,
        problemUrl = `${siteUrl}/problems/particular/${personDetail.problemId}`;

  return (
    <Panel bsStyle="primary">

      <Panel.Heading>
        <Panel.Title className="d-flex justify-content-between" componentClass="h6">
          <span>Единое цифровое окно обратной связи</span>
          <i className="fa fa-times close-widget" aria-hidden="true" onClick={props.close}/>
        </Panel.Title>
      </Panel.Heading>

      <Panel.Body className="finish-screen">
        <h4><strong>Ваше сообщение № {personDetail.problemId}</strong>
          <br/>
          принято на портал <a href={siteUrl}>{config.PORTAL_NAME}</a>
        </h4>
        <p className="margin-bottom-20 term">
          <span>Максимальный срок рассмотрения</span>:
          <strong>&nbsp;{typicalProblem.deadline} дней</strong>
        </p>
        <p className="margin-bottom-20 description">После проверки Модератором портала сообщение будет передано ответственному исполнителю для решения проблемы</p>
        <p className="description">Вы всегда можете посмотреть текущий статус работ по Вашему сообщению <a href={problemUrl}>на сайте</a></p>
        <hr/>
        <div className="d-flex justify-content-center text-center">
          <p><strong>Спасибо, что делаете {config.REGION_NAME_GENITIVE} лучше!</strong></p>
        </div>
      </Panel.Body>

    </Panel>
  )
};

const mapStateToProps = state => ({
  personDetail: state.personDetail,
  siteUrl: state.siteUrl,
  config: state.config,
  typicalProblem: state.typicalProblem
});
const mapDispatchToProps = dispatch => bindActionCreators(ActionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FinishScreen);