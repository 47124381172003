import React, {useState, useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ActionCreators from '../actions';
import {useDropzone} from 'react-dropzone';


const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  borderRadius: 2,
  border: '2px dashed #337ab7',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer'
};

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap'
};

const thumb = {
  display: 'flex',
  borderRadius: 4,
  border: '1px solid #eaeaea',
  margin: 8,
  width: 92,
  height: 92,
  padding: 4,
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

let thumbs = [],
  refThumbs = [];

const ERROR_AMOUNT_FILE = gettext("You cannot attach more than 5 files"),
  ERROR_SIZE = gettext("File size cannot exceed 10 megabytes"),
  ERROR_TYPE = gettext("Uploaded file must be an image (jpg/jpeg/png/pdf)");

const Dropzone = props => {
  const {entityId, siteUrl, loading, getImages, images, uploadImages, removeImage} = props;

  const [refDropzone, setRefDropzone] = useState(null),
    [posXDropzone, setPosXDropzone] = useState(null),
    [posYDropzone, setPosYDropzone] = useState(null),
    [showError, setShowError] = useState(null);

  useEffect(() => {
    getImages(siteUrl, entityId);
  }, []);

  useEffect(() => {
    if (loading.some(item => item === "uploadImages")) {
      props.removeLoading("uploadImages");
      props.removeLoading("getImages");
      props.countImage(images.length + 1);
      getImages(siteUrl, entityId);
    } else if (loading.some(item => item === "removeImages")) {
      props.removeLoading("removeImages");
      props.removeLoading("getImages");
      props.countImage(images.length - 1);
      getImages(siteUrl, entityId);
    } else if (!loading.some(item => item === "getImages")) {
      getImages(siteUrl, entityId);
    } else {
      props.countImage(images.length);
    }
    setPos();
  }, [loading.length]);

  useMemo(() => {
    getImages(siteUrl, entityId);
    refThumbs = [];
    thumbs = images.map(image => (
      <div className="d-flex flex-column align-items-center" key={image.id} style={{position: "relative"}}
           ref={ref => refThumbs.push(ref)} data-id={image.id} data-is-pdf={image.original_filename.endsWith('pdf')}>
        <div style={thumb}>
          <div style={thumbInner}>
            <img
              src={image.image}
              style={img}
            />
          </div>
        </div>
      </div>
    ));
  }, [images.length]);

  const maxFileSize = 10485760; // 10 MB

  const {
    getRootProps,
    getInputProps,
    isDragReject,
  } = useDropzone({
    noKeyboard: true,
    multiple: true,
    onDrop: acceptedFiles => {
      setShowError(null);
      setPos();
      if (images.length + acceptedFiles.length > 5) {
        setShowError(ERROR_AMOUNT_FILE);
        acceptedFiles = acceptedFiles.slice(0, 5 - images.length);
      } else {
        acceptedFiles.map(file => {
          if (file.size > maxFileSize)
            return setShowError(ERROR_SIZE);

          const accept = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];
          if (!accept.includes(file.type))
            return setShowError(ERROR_TYPE);

          uploadImages(siteUrl, file, entityId);
          props.addLoading("uploadImages");
        });
      }
    }
  });

  const setPos = () => {
    if (refDropzone) {
      const areaDropzone = refDropzone.getBoundingClientRect();
      setPosXDropzone(areaDropzone.left);
      setPosYDropzone(areaDropzone.top);
    }
  };


  return (
    <>
      <div style={{position: "relative"}} ref={ref => setRefDropzone(ref)}>
        <div style={baseStyle} {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps({
            onClick: () => setPos()
          })} />
          {!images.length ?
            <div className="d-flex justify-content-center align-items-center w-100 h-100">
              <p>Перетащите файлы в эту область или кликните по ней для добавления файла</p>
            </div>
            :
            <aside style={thumbsContainer}>
              {thumbs}
            </aside>
          }
        </div>
        {refThumbs.map((ref, thumbKey) => {
          if (ref) {
            const areaRect = ref.getBoundingClientRect(),
              posX = areaRect.left - posXDropzone,
              posY = areaRect.top - posYDropzone,
              id = ref.getAttribute('data-id'),
              isPdf = JSON.parse(ref.getAttribute('data-is-pdf'));

            return (
              <div className="cover" key={thumbKey} style={{left: posX, top: posY}}>
                <div className="delete-image" onClick={() => {
                  removeImage(siteUrl, id, isPdf);
                  setShowError(null);
                }}>
                  <i className="fa fa-times" aria-hidden="true"/>
                </div>
              </div>
            )
          } else
            return null
        })}
      </div>
      <p>* не более 5 файлов. Каждый файл не более 10MB. Формат файлов: jpg, png или pdf</p>
      {showError ? <p className="text-center alert alert-danger center">{showError}</p> : null}
    </>
  );
};


const mapStateToProps = state => ({
  images: state.images,
  loading: state.loading
});
const mapDispatchToProps = dispatch => bindActionCreators(ActionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Dropzone);