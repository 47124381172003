import {actionTypes, ROUTES} from "../constants/actionTypes";

export const router = (state = ROUTES.STEP_ONE, action) => {
  switch (action.type) {
    case actionTypes.SET_ROUTE:
      return action.value;

    default:
      return state;
  }
};

export const personDetail = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_PERSON_DETAIL:
      return {...action.data, problemId: action.data.particular_problem_draft_id, isOperator: action.isOperator};

    default:
      return state
  }
};

export const problem = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_PROBLEM_DETAIL:
      return action.problem ;

    case actionTypes.SET_DATA:
      return {...state, ...action.data};

    default:
      return state
  }
};

export const config = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_CONFIG:
      return action.config;

    default:
      return state;
  }
};

export const loading = (state = [], action) => {
  switch (action.type) {
    case actionTypes.ADD_LOADING:
      return state.includes(action.loading) ? state : [...state, action.loading];

    case actionTypes.REMOVE_LOADING:
      if (!action.clearAll)
        return state.filter(loading => loading !== action.loading);
      return ["siteUrl"];

    default:
      return state;
  }
};

export const themes = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_THEMES:
      return action.themes;

    default:
      return state;
  }
};

export const siteUrl = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SET_SITE_URL:
      return action.siteUrl;

    default:
      return state;
  }
};

export const place = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_PLACE:
      return action.place;

    default:
      return state;
  }
};

export const address = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.SEARCH_ADDRESSES:
      return {...state, searchAddresses: action.searchAddresses};

    case actionTypes.GET_ADDRESS:
      return {...state, received: action.searchAddress};

    default:
      return state;
  }
};

export const typicalProblemId = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SET_TYPICAL_PROBLEM_ID:
      return action.typicalProblemId;

    default:
      return state;
  }
};

export const typicalProblem = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.GET_TYPICAL_PROBLEM_DETAIL:
      return {
        image_status: action.image_status,
        deadline: action.deadline,
        conditions: action.conditions,
      };

    default:
      return state;
  }
};

export const amountImage = (state = 0, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_AMOUNT_IMAGE:
      return action.image;

    default:
      return state;
  }
};

export const errors = (state = null, action) => {
  switch (action.type) {
    case actionTypes.ERROR_GET_PERSON_DETAIL:
    case actionTypes.ERROR_GET_PROBLEM_DETAIL:
    case actionTypes.ERROR_GET_CONFIG:
    case actionTypes.ERROR_SET_DATA:
    case actionTypes.ERROR_GET_PLACE:
    case actionTypes.ERROR_GET_THEMES:
    case actionTypes.ERROR_SUBMIT_PROBLEM:
    case actionTypes.ERROR_SEARCH_ADDRESS:
    case actionTypes.ERROR_GET_TYPICAL_PROBLEM_DETAIL:
      return action.error;

    case actionTypes.SET_ROUTE:
      return null;

    default:
      return state;
  }
}
