import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import ActionCreators from '../actions';


const Errors = props => {
  const {errors} = props;

  if (!errors)
    return null

  return (
    <div className="errors-container">
      <span>{typeof errors === "object" ? Object.values(errors)[0] : errors}</span>
    </div>
  );
};

const mapStateToProps = state => ({
  errors: state.errors
});
const mapDispatchToProps = dispatch => bindActionCreators(ActionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Errors);